import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const ZlotyUlanKaluszynPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Złoty ułan w Kałuszynie" />
      <ReturnToPrevPage to={'/gminy/kaluszyn'}>
        Materiały dotyczące gminy Kałuszyn
      </ReturnToPrevPage>
      <Header1>Złoty ułan w Kałuszynie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Pomnik Złotego Ułana poświęcony jest pamięci związanego z 11 Pułkiem
          Ułanów Legionowych rotmistrzowi Andrzejowi Żylińskiemu. Jednostka ta
          rozpoczęła zwycięzką bitwę pod Kałuszynem 12 września 2020 roku.
          Projekt rzeźbiarza poznańskiego rzeźbiarza Roberta Sobocińskiego
          dolany jest z pozłacanego brązu.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/kaluszyn/zloty-ulan/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/kaluszyn/zloty-ulan/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ZlotyUlanKaluszynPage
